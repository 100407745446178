// import { login, getInfo, logout } from '@/api/login'
import { PostToken, login } from '@/api/login/index.js'
import { reqElevatorProjectSelectList } from '@/api/home/index.js'
import { getToken, setToken, removeToken, setUserInfo, removeUserInfo, getUserInfo, setDataSourceType } from '@/common/utils/auth'
import { resetRouter } from '@/router'
import { Message } from 'element-ui'
import store from '@/store'

const customizedDeptIds = ['8291576032986464256']

const getDefaultState = () => {
    return {
        token: getToken(),
        user: getUserInfo() ? JSON.parse(getUserInfo()) : null,
        roles: []
    }
}

const state = getDefaultState()

const mutations = {
    RESET_STATE: (state) => {
        Object.assign(state, getDefaultState())
    },
    SET_TOKEN: (state, token) => {
        state.token = token
    },
    SET_USER: (state, user) => {
        state.user = user
    },
    SET_ROLES: (state, roles = []) => {
        // state.roles = ['admin'].concat(roles)
        state.roles = roles
    },
}

const actions = {
    // user login
    login({ commit }, userInfo) {
        const { username, password, code, uuid } = userInfo
        return new Promise((resolve, reject) => {
            PostToken(userInfo).then(res => {
                setToken(res.data.access_token)

                commit('SET_TOKEN', res.data.access_token)
                // this.$store.commit('SET_USER', this.form.userName)
                login(userInfo).then(res2 => {
                    if (res2.code == '0000') {
                        const data = res2.info;
                        data.userDetail.v_system_title = res2.info.systemLogInfo.v_system_title
                        data.userDetail.v_log_file_url = res2.info.systemLogInfo.v_log_file_url
                        data.userDetail.customized = customizedDeptIds.indexOf(data.userDetail.deptId)> -1
                        commit("SET_USER", data.userDetail);
                        setUserInfo(JSON.stringify(data.userDetail));
                        setDataSourceType(data.userDetail.iWuyePlatform)
                        // if (data.userDetail.iWuyePlatform && data.userDetail.iWuyePlatform == 2)
                        store.dispatch('settings/changeTheme', {
                                'theme': 'dark'
                        })
                        // else
                        //     store.dispatch('settings/changeTheme', {
                        //         'theme': 'light'
                        //     })
                        reqElevatorProjectSelectList({}).then((res) => {
                            let projects = res.info ? res.info : []
                            data.userDetail.v_project_id = projects.length ? projects[0].v_project_id : ''
                            commit("SET_USER", data.userDetail);
                            setUserInfo(JSON.stringify(data.userDetail));
                            resolve()
                        }).catch((err) => {

                            resolve()
                        })
                    } else {
                        reject()
                        Message.error(res2.msg)
                    }
                })
            }).catch(err => {
                reject(err)
            })

            // login({
            //     username: username.trim(),
            //     password,
            //     code,
            //     uuid,
            //     authClient: 'mashine-platform'
            // }).then(response => {
            //     const { data } = response
            //     const permList = data.userInfo.permList
            //     if (!permList.length) {
            //         Message.error('您没有任何权限')
            //         reject()
            //         return
            //     }
            //     const userToken = data.tokenHead + data.token
            //     commit('SET_TOKEN', userToken)
            //     setToken(userToken)
            //     commit('SET_ROLES', permList)
            //     commit('SET_USER', data.userInfo)
            //     resolve()
            // }).catch(error => {
            //     reject(error)
            // })
        })
    },

    // get user info
    getInfo({ commit }) {
        return new Promise((resolve, reject) => {
            getInfo().then(response => {
                const { data } = response
                commit('SET_ROLES', data.permList)
                commit('SET_USER', data)
                resolve(data)
            }).catch(error => {
                reject(error)
            })
        })
    },

    // user logout
    logout({ commit, state }) {
        return new Promise((resolve, reject) => {
            // logout(state.token).then(() => {
            removeToken() // must remove  token  first
            removeUserInfo()
            commit('permission/CLEAR_ROUTES', null, { root: true })
            // commit('system/CLEAR_ALL', null, { root: true })
            // commit('RESET_STATE')
            resetRouter()
            resolve()
            // }).catch(error => {
            //     reject(error)
            // })
        })
    },

    // remove token
    resetToken({ commit }) {
        return new Promise(resolve => {
            removeToken() // must remove  token  first
            commit('RESET_STATE')
            resolve()
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}