<template>
  <div>
    <Row>
      <Col :xs="24" :sm="24" :md="24" :lg="24">
        <Table
          id="table"
          size="small"
          ref="table"
          :row-key="dataIndex"
          :load-data="handleLoadDataVal"
          :max-height="maxHeightVal"
          :loading="tabelLoadingVal"
          :columns="tabelConfigVal"
          :data="tabelDataVal"
          highlight-row
          @on-selection-change="onSelect"
          @on-select-cancel="onSelect"
          @on-select-all-cancel="onSelectCancelAll"
        ></Table>
      </Col>
    </Row>
    <div style="margin-bottom: 10px"></div>
    <Row type="flex" justify="center" v-if="PageShowFlagVal">
      <!-- <Col :xs="16" :sm="16" :md="16" :lg="16"> -->
        <Page
          :total="totalRecordCntVal"
          :current="pageIndex"
          :page-size="pageSize"
          show-total
          show-elevator
          show-sizer
          @on-page-size-change="searchChangepageSize"
          @on-change="search"
          :page-size-opts="[10, 20, 30, 40, 50]"
        />
      <!-- </Col> -->
      <!-- <Col :xs="8" :sm="8" :md="8" :lg="8"> -->
        <Form inline :label-width="100" label-position="right">
          <FormItem label="当前页数量:" v-show="pageSizeShowflag">
            <Input
              v-model="tabelDataVal.length"
              :disabled="true"
              style="width: 60px"
            />
          </FormItem>
          <FormItem
            label="已选数量:"
            v-show="tabelConfigVal[0].type === 'selection'"
          >
            <Input
              v-model="selectionSize"
              :disabled="true"
              style="width: 60px"
            />
          </FormItem>
        </Form>
      <!-- </Col> -->
    </Row>
  </div>
</template>
<script>
export default {
  name: "tblcomponents",

  data() {
    /**
     * set对象与数组之间的转换
     * var arr = [1,2,3,4,4];
     * var set = new Set(arr) //数组转换set对象
     * set //{1,2,3,4}
     * //方法一
     * Array.from(set) //[1,2,3,4]
     * //方法二
     * [...set] //[1,2,3,4]
     */
    return {
      pageIndex: 1,
      pageSize: 10,
      selectionPageAll: new Set(), // 已经选中的数据
      str: [],
      selectionSize: 0, // 由于选中后set集合 无法实时更新问题 提取出长度
      pageSizeShowflag: true,
    };
  },
  props: {
    needFlag: {
      type: Boolean,
      default: false,
    }, //是否回显全部数据
    tabelLoadingVal: {
      type: Boolean,
    },
    maxHeightVal: {
      type: Number,
    },
    totalRecordCntVal: {
      // 总条数
      type: Number,
      required: true,
    },
    handleLoadDataVal: {
      // 总条数
      type: Function,
    },
    tabelConfigVal: {
      // 当前表格配置
      type: Array,
      required: true,
    },
    tabelDataVal: {
      // 当前页的数据
      type: Array,
      required: true,
    },
    dataIndex: {
      // 数据的唯一标识
      type: String,
      default: "id",
    },
    formItem: {
      // 查询条件
      type: Object,
      require: true,
    },
    PageShowFlagVal: {
      // 查询条件
      type: Boolean,
      default: true,
    },
    parentSelect: {
      // 选中触发父页面事件
      type: Function,
    },
    UpdateparentSelect: {
      // 选中触发父页面事件
      type: Function,
    },
    defaultSelect: {
      // 默认选中
      type: String,
    },
    upDate: [],
    autoCalcWidth: {
      // 自动计算宽度
      type: Boolean,
      default: true,
    },
  },
  created() {
    // 将页面中的查询对象中加入 分页条件
    if (this.PageShowFlagVal) {
      if (this.formItem) {
        this.formItem["pageIndex"] = this.pageIndex;
        this.formItem["pageSize"] = this.pageSize;
      }
    }
    this.selectionSize = this.selectionPageAll.size;
    // 优化表格 开启后，文本将不换行，超出部分显示为省略号，并用 Tooltip 组件显示完整内容
    for (let i = 0; i < this.tabelConfigVal.length; i++) {
      if (this.tabelConfigVal[i]["tree"]) {
        this.tabelConfigVal[i]["tooltip"] = false;
      } else {
        this.tabelConfigVal[i]["tooltip"] = true;
      }
   

      // 如果是 tree 数据
      if (this.tabelConfigVal[i].tree) {
        this.pageSizeShowflag = false;
      }
    }
  },
  watch: {
    tabelDataVal(newName, oldName) {
      if (newName.length > 0) {
        this.echoDataSelection();
        if (this.formItem) {
          // this.formItem['pageIndex'] = 1
          // this.formItem['pageSize'] = 10
          this.formItem["pageIndex"] = this.pageIndex;
          this.formItem["pageSize"] = this.pageSize;
        }
      }
    },
    defaultSelect(newVal, oldVal) {
      if (newVal.length > 0) {
        this.selectionPageAll = new Set(newVal.split(","));
      } else {
        this.selectionPageAll = new Set();
      }
      this.selectionSize = this.selectionPageAll.size;
      this.str = Array.from(this.selectionPageAll);
      this.echoDataSelection();
    },
    formItem: {
      handler: function() {
        this.formItem["pageIndex"] = 1;
      },
      deep: true,
    },
  },
  methods: {
    search(pageIndex, pageSize) {
      if (this.PageShowFlagVal) {
        this.pageIndex = pageIndex || this.pageIndex;
        this.pageSize = pageSize || this.pageSize;
        this.formItem["pageIndex"] = this.pageIndex;
        this.formItem["pageSize"] = this.pageSize;
      }
      this.$emit("search");
    },
    searchChangepageSize(pageSize) {
      this.search(this.pageIndex, pageSize);
    },
    // 选择记忆
    onSelect(selection, row) {
      if (row) {
        this.selectionPageAll.delete(
          row[this.dataIndex || this.tabelConfigVal[1].key]
        );
        this.selectionSize = this.selectionPageAll.size;
        let index = this.str.indexOf(row[this.dataIndex]);
        this.str.splice(index, 1);
        return;
      }
      for (let i = 0; i < selection.length; i++) {
        // this.upDate = this.upDate.concat(selection[i])
        this.selectionPageAll.add(
          selection[i][this.dataIndex || this.tabelConfigVal[1].key]
        );
        if (this.str.indexOf(selection[i][this.dataIndex]) < 0) {
          this.str.push(selection[i][this.dataIndex]);
        }
      }
      this.selectionSize = this.selectionPageAll.size;
      this.$emit("parentSelect", this.str);

      this.$emit("UpdateparentSelect", selection);
    },
    // 多选取消
    onSelectCancelAll() {
      for (let i = 0; i < this.tabelDataVal.length; i++) {
        this.selectionPageAll.delete(
          this.tabelDataVal[i][this.dataIndex || this.tabelConfigVal[1].key]
        );
        let index = this.str.indexOf(this.tabelDataVal[i][this.dataIndex]);
        this.str.splice(index, 1);
      }
      this.selectionSize = this.selectionPageAll.size;
      this.$emit("parentSelect", this.str);
    },

    // 回显选中值
    echoDataSelection() {
      for (const setItem of this.selectionPageAll) {
        for (const tblDataItem of this.tabelDataVal) {
          if (
            setItem ===
            tblDataItem[this.dataIndex || this.tabelConfigVal[1].key]
          ) {
            tblDataItem["_checked"] = true;
          }
        }
      }
    },
    // 对外提供 选中数据 方法
    getSelectionPageAll() {
      return this.selectionPageAll;
    },
  },
};
</script>
<style lang="less">

.ivu-table-header thead tr th {
  height: 50px;
}

.ivu-table th {
  background-color: #edeef6;
  font-weight:700;
  color: #000;
  font-size: 14px;
}
.ivu-table-small td{
  height: 50px;
}


</style>
