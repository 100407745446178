import http from '@/common/utils/http'
import requestAuth from '@/common/utils/http/requestAuth'

/** 获取token */
export function PostToken(data) {
  const dataTemp = `username=${data.userName}&password=${data.passWord}&grant_type=password&scope=all&client_id=client&client_secret=secret`
  return requestAuth({
    url: '/oauth/token',
    method: 'post',
    data: dataTemp
  })
}

/** 拉取取权限 */
export function login(data) {
  return http({
    url: 'loginByMobile',
    method: 'post',
    data
  })
}
