import Cookies from 'js-cookie'

const ThemeKey = 'theme'

export function getTheme() {
  return Cookies.get(ThemeKey)
}

export function setTheme(theme) {
  return Cookies.set(ThemeKey, theme)
}

