import defaultSettings from '@/settings'
import { getTheme, setTheme, } from '@/common/utils/config'

const { showSettings, fixedHeader, sidebarLogo, theme } = defaultSettings

const state = {
    showSettings: showSettings,
    fixedHeader: fixedHeader,
    sidebarLogo: sidebarLogo,
    theme: getTheme() ? getTheme() : theme
}

const mutations = {
    CHANGE_SETTING: (state, { key, value }) => {
        // eslint-disable-next-line no-prototype-builtins
        if (state.hasOwnProperty(key)) {
            state[key] = value
        }
    },

    CHANGE_THEME: (state, data) => {
        // eslint-disable-next-line no-prototype-builtins
        state.theme = data.theme
    },


}

const actions = {
    changeSetting({ commit }, data) {
        commit('CHANGE_SETTING', data)
    },

    changeTheme({ commit }, data) {
        return new Promise((resolve, reject) => {
            commit('CHANGE_THEME', data)
            setTheme(data.theme)
            resolve()
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}