import axios from 'axios'
import { Message } from 'view-design'

// create an axios instance
const service = axios.create({
  baseURL: 'https://iot.shmashine.com/auth/', // 授权认证的 的 base_url
  // baseURL: 'http://localhost:8091/auth/', // 授权认证的 的 base_url
  timeout: 300000 // 超时时间
})
// request interceptor
service.interceptors.request.use(
  (config) => {
    config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
    config.data
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)
// response interceptor
service.interceptors.response.use((response) => {
  return response
},
(error) => {
  const res = error.response.data
  if (res.error && res.error === 'invalid_grant') {
    Message.error({
      content: '密码错误!请重试。',
      duration: 5
    })
    return Promise.reject(error)
  } else if (res.error && res.error === 'unauthorized') {
    Message.error({
      content: '用户不存在！',
      duration: 5
    })
    return Promise.reject(error)
  }
  console.log(error) // for debug
  Promise.reject(error)
  Message.error({
    content: '请求失败！',
    duration: 5
  })
// eslint-disable-next-line no-sequences
})

export default service
