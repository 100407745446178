<template>
    <div>
        <!-- <div @click="onSwitchBtnTapped">{{eventSwitch?'播报已打开':'播报已关闭'}}</div> -->
        <audio muted loop autoplay="autoplay" ref="audio">Your browser does not support the audio element.</audio>
    </div>
</template>
<script>
import { searchFaultList } from '@/api/home/index.js'
export default {
    data() {
        return {
            isPlaying: false,
            isLoading: false,
            interval: null,
            formItem: {
                eType: null,
                vProjectId: '',
                vElevatorCode: '',
                dtReportTime: this.formatDate(-0) + '',
                dtEndTime: '',
                iUncivilizedBehaviorFlag: 0,
                iElevatorType: 1,
                iFaultType: '',
                iInstallStatus: 1,
                // vEventType: "8",
                iStatus: 0,
                villageId: "",
            },
            playTimes: 0,
            alertKey: '',
            eventIds: '',
        }
    },
    computed: {
        eventSwitch() {
            return this.$store.state.event.eventSwitch
        },
        USERINFO() {
            return this.$store.state.user.user
        },
        eventList() {
            return this.$store.state.event.eventList
        }
    },
    mounted() {},
    methods: {
        startMonitor() {
            if (this.interval)
                clearInterval(this.interval)
            this.interval = setInterval(() => {
                if (this.isLoading || !this.USERINFO || Object.keys(this.USERINFO).length <= 0) return
                this.isLoading = true
                searchFaultList(this.buildParams(this.formItem)).then(res => {
                    let list = res.info.list
                    let play = this.updateByFaultList(list)
                    if (play)
                        this.statPlay()
                    else
                        this.stopIfNeeded()
                    this.isLoading = false
                }).catch(() => {
                    this.isLoading = false
                })
            }, 3000)
        },
        buildParams(params) {
            // params.vProjectId = this.USERINFO.v_project_id
            return params
        },

        updateByFaultList(list) {
            let faultIds = list.map((item) => {
                return item.v_fault_id
            }).join(',')
            if (this.alertKey == faultIds)
                return false
            else {
                this.alertKey = faultIds
                this.playTimes = 0
                this.$store.dispatch('updateEventList', { data: list })
                return true
            }
        },

        statPlay() {
            if (!this.eventSwitch) {
                this.stopIfNeeded()
                return
            }
            if (this.playTimes >= 3) return
            if (this.isPlaying) return
            this.play()
        },
        play() {
            this.$refs.audio.src = require('@/assets/audio/alarm.aac')
            this.$refs.audio.muted = false
            this.$refs.audio.loop = true
            this.$refs.audio.play()
            this.isPlaying = true
            this.playTimes++
        },
        stop() {
            if (this.$refs.audio) {
                this.$refs.audio.pause()
                this.$refs.audio.currentTime = 0
            }
            this.isPlaying = false
        },
        stopIfNeeded() {
            if (this.isPlaying)
                this.stop()
        },
        openSwitch() {
            this.$refs.audio.src = require('@/assets/audio/muted.aac')
            this.$refs.audio.muted = true
            this.$refs.audio.loop = false
            this.$refs.audio.play()
            this.$store.dispatch('updateEventSwitch', { data: true })
        },
        closeSwitch() {
            this.stopIfNeeded()
            this.$store.dispatch('updateEventSwitch', { data: false })
        },
        onSwitchBtnTapped() {
            this.eventSwitch ? this.closeSwitch() : this.openSwitch()
        },
    },
    destroyed() {
        if (this.interval)
            clearInterval(this.interval)
        this.stopIfNeeded()
    },
}
</script>
<style scoped>
</style>