import Cookies from 'js-cookie'

const TokenKey = 'vue_admin_template_token'
const UserInfoKey = 'vue_admin_template_userinfo'
const DataSourceTypeKey = 'vue_admin_datasource_type'


export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function setUserInfo(userInfo) {
  return Cookies.set(UserInfoKey, userInfo)
}

export function getUserInfo() {
  return Cookies.get(UserInfoKey)
}

export function removeUserInfo() {
  return Cookies.remove(UserInfoKey)
}

export function getDataSourceType() {
 return Cookies.get(DataSourceTypeKey) 
}

export function setDataSourceType(type) {
  return Cookies.set(DataSourceTypeKey, type)
}

export function removeDataSourceType() {
   return Cookies.remove(DataSourceTypeKey) 
}