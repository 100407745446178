<template>
    <div id="app">
        <router-view />
        <FaultAlarm ref="faultAlarm" />
    </div>
</template>
<script>
import FaultAlarm from '@/components/alarm/index'
import { getToken } from '@/common/utils/auth'
export default {
    name: 'App',
    data() {
        return {
            scalesNum: 1, // 缩放比例
        }
    },
    components: {
        FaultAlarm
    },
    computed: {
        theme() {
            return this.$store.getters.theme
        },
        eventSwitch() {
            return this.$store.state.event.eventSwitch
        }
    },
    mounted() {
        // 计算缩放比例
        const zoom = () => {
            let width = document.documentElement.clientWidth
            let domBody = document.getElementsByTagName('body')[0]
            if (width > 1920) {
                domBody.style.zoom = 1
            } else {
                domBody.style.zoom = width / 19.2 + '%'
            }

        }
        zoom()
        // 窗口大小变化时，需要重算
        window.addEventListener('resize', zoom, false)
        window.document.body.setAttribute("data-theme", this.theme);
        this.checkOpenAlarm()
    },

    methods: {
        checkOpenAlarm() {
            const token = getToken()
            if (token) {
                if (!this.eventSwitch) {
                    this.$Modal.confirm({
                        title: '温馨提示',
                        content: '点击确定开启语音播报',
                        loading: true,
                        onOk: () => {
                            this.$refs.faultAlarm.openSwitch()
                            this.$Message.success('开启成功')
                            this.$Modal.remove()
                        },
                    })
                }
            }
        }
    },
    watch: {
        theme(newVal, oldVal) {
            if (newVal != oldVal) {
                window.document.body.setAttribute("data-theme", newVal);
            }
        }
    }
}
</script>
<style>
.adaption-box {
    background: red;
    left: 0;
    top: 0;
    position: absolute;
}
</style>