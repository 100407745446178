import store from '@/store'

const event = {
    state: {
        eventList: [],
        eventSwitch: false,
    },
    getters: {
        getEventList: (state) => state.eventList,
        getEventSwitch: (state) => state.eventSwitch,
    },
    mutations: {
        setEventList(state, payload) {
            state.eventList = payload
        },
        setEventSwitch(state, payload) {
            state.eventSwitch = payload
        },
    },
    actions: {
        updateEventList({ commit }, reqData) {
            commit("setEventList", reqData && reqData.data ? reqData.data.map((item)=> {
                return item.villageId
            }) : [])
        },
        updateEventSwitch({ commit }, reqData) {
            commit("setEventSwitch", reqData && reqData.data ? reqData.data : false)
        }
    },
}

export default event