import Vue from 'vue'
import './plugins'
import http from './common/utils/http'
import router from './router'
import store from './store/index'
import '@/common/styles/index.scss' // global css
import App from './App.vue'
import ViewUI, { Row } from 'view-design'
import 'view-design/dist/styles/iview.css'
import tblcomponents from '@/components/tblcomponents.vue'
import { formatDate, formatDateYMD} from './common/utils/date/index'
import * as echarts from 'echarts'
import BaiduMap from '@/components/vue-baidu-map'
import HighchartsVue from 'highcharts-vue' // highcharts 组件库
import '@/permission' // permission control
import { getSystemSelection } from './api/global/globalApi' // 全局下拉框接口

Vue.prototype.formatDate = formatDate
Vue.prototype.$formatDateYMD = formatDateYMD
Vue.prototype.$getSystemSelection = getSystemSelection

Vue.prototype.$echarts = echarts

Vue.use(HighchartsVue)
Vue.component('tblcomponents', tblcomponents)

Vue.prototype.axios = http

Vue.use(ViewUI)
Vue.use(BaiduMap, {
  ak: 'ps3iOxIfketZQ3RQHzzXyRpOz6uGzLpY'
})


new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
