import router from './router'
import store from './store'
import { Message } from 'element-ui'
// import NProgress from 'nprogress' // progress bar
// import 'nprogress/nprogress.css' // progress bar style
import { getToken } from '@/common/utils/auth' // get token from cookie
import getPageTitle from '@/common/utils/get-page-title'

// NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = ['/login'] // no redirect whitelist

router.beforeEach(async (to, from, next) => {
    // start progress bar
    // NProgress.start()
    // set page title
    document.title = getPageTitle(to.meta.title)

    // determine whether the user has logged in
    const hasToken = getToken()

    if (hasToken) {
        if (to.path === '/login') {
            // if is logged in, redirect to the home page
            next({ path: '/' })
            // NProgress.done()
        } else {
            // determine whether the user has obtained his permission roles through getInfo
            // const hasRoles = store.getters.roles && store.getters.roles.length > 0
            const permission_routes = store.getters.permission_routes
            if (permission_routes.length === 0) {
                try {
                    // get user info
                    // note: roles must be a object array! such as: ['admin'] or ,['developer','editor']
                    // const { permList } = await store.dispatch('user/getInfo')
                    const roles = []
                    // if (!roles.length) {
                    //   await store.dispatch('user/logout')
                    //   Message.error('您没有任何权限')
                    //   next(`/login`)
                    //   NProgress.done()
                    //   return
                    // }

                    // generate accessible routes map based on roles
                    store.dispatch('permission/generateRoutes', roles).then(accessRoutes => {
                        // dynamically add accessible routes
                        router.addRoutes(accessRoutes)

                        next({ ...to, replace: true })

                    })

                } catch (error) {
                    // remove token and go to login page to re-login
                    await store.dispatch('user/resetToken')
                    Message.error(error || 'Has Error')
                    next(`/login?redirect=${to.path}`)
                    // NProgress.done()
                }
            } else {
                next()
            }
            //
            // if (hasRoles) {
            //   const permission_routes = store.getters.permission_routes
            //   // 登录后如果没路由，重新搞一下路由
            //   if (permission_routes.length === 0) {
            //     await store.dispatch('permission/generateRoutes', store.getters.roles)
            //   }
            //   next()
            // } else {
            //   try {
            //     // get user info
            //     // note: roles must be a object array! such as: ['admin'] or ,['developer','editor']
            //     const { permList } = await store.dispatch('user/getInfo')
            //     const roles = permList
            //
            //     // generate accessible routes map based on roles
            //     const accessRoutes = await store.dispatch('permission/generateRoutes', roles)
            //
            //     // dynamically add accessible routes
            //     router.addRoutes(accessRoutes)
            //
            //     // hack method to ensure that addRoutes is complete
            //     // set the replace: true, so the navigation will not leave a history record
            //     next({ ...to, replace: true })
            //   } catch (error) {
            //     // remove token and go to login page to re-login
            //     await store.dispatch('user/resetToken')
            //     Message.error(error || 'Has Error')
            //     next(`/login?redirect=${to.path}`)
            //     NProgress.done()
            //   }
            // }
        }
    } else {
        /* has no token*/
        if (whiteList.indexOf(to.path) !== -1) {
            // in the free login whitelist, go directly
            next()
        } else {
            // other pages that do not have permission to access are redirected to the login page.
            next(`/login?redirect=${to.path}`)
            // NProgress.done()
        }
    }
})

router.afterEach(() => {
    // finish progress bar
    // NProgress.done()
})