import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import createLogger from 'vuex/dist/logger'

import permission from './modules/permission'
import settings from './modules/settings'
import user from './modules/user'
import event from './modules/event'

Vue.use(Vuex)
const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
    getters,
    modules: {
        permission,
        settings,
        user,
        event
    },
    plugins: debug ? [createLogger()] : []
})